<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2022-01-21 14:56:40
 * @LastEditors: ------
 * @LastEditTime: 2022-02-25 13:26:03
-->
<template>
  <div>
    <router-view />
    <van-tabbar v-model="active" fixed safeAreaInsetBottom route>
      <van-tabbar-item icon="wap-home-o" replace to="/">首页</van-tabbar-item>
      <van-tabbar-item icon="shield-o" replace to="/customerInfo"
        >创建客户</van-tabbar-item
      >
      <van-tabbar-item icon="notes-o" replace to="/file"
        >护理员档案</van-tabbar-item
      >

      <van-tabbar-item name="customer" icon="description" replace to="/manage"
        >服务合同管理</van-tabbar-item
      >
    </van-tabbar>
    <!-- <div class="pop">
      <van-popover v-model="showPopover" placement="top" trigger="click">
        <van-grid
          square
          clickable
          :border="false"
          column-num="4"
          style="width: 300px"
        >
          <van-grid-item
            v-for="(i, index) in pop"
            :key="index"
            :text="i.title"
            :icon="i.icon"
            :to="i.to"
            @click="showPopover = false"
          />
        </van-grid>
        <template #reference>
          <van-button type="primary" size="small" icon="plus"></van-button>
        </template>
      </van-popover>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      active: "customer",
      showPopover: false,
      pop: [
        {
          icon: "friends-o manager-o",
          title: "+客户信息",
          to: "/customerInfo",
        },
        {
          icon: "manager-o",
          title: "+家庭成员",
          to: "/",
        },
        {
          icon: "notes-o",
          title: "+访谈记录",
          to: "/easy",
        },
        {
          icon: "newspaper-o",
          title: "+服务合同",
          to: "/",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped lang="less">
.pop {
  width: 20%;
  position: fixed;
  z-index: 99;
  bottom: 36px;
  left: 150px;
  right: 0;
  text-align: center;
  /deep/ .van-popover__wrapper {
    button {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      font-size: 22px;
      border: none;
      background: linear-gradient(-90deg, #5038fa, #1476ff);
    }
  }
}
</style>
